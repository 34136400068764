.wholeDateWrapper {
  padding: 40px 24px;

}
.wholeDate {
  display: flex;
  gap: 40px;
}
.mainDateImgWrapper {
  width: 100%;
  height: 100%;

}
.mainDateImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  border-radius: 5px;
}
.wholeDateLeft {
  width: 58%;
}
.wholeDateRight {
  width: 38%;
}
.wholeDateName {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}
.wholeDateLocation {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
}
.wholeDateSexWrapper {
  display: flex;
  margin-bottom: 30px;
}
.wholeDateSexWrapper .sex {
  justify-content: flex-start;
  width: 30%;
}
.wholeDateInfoWrapper {
  /*padding-top: 15px;*/
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  margin-bottom: 15px;
}
.wholeDateInfoLeft {
  width: 50%;

}
.wholeDateInfoRight {
  width: 50%;
}
.wholeDateInfoLabel {
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 7px;
}
.wholeDateInfoValue {
  font-size: 16px;
  font-weight: 400;
}
.red {
  color: red;
}
.btnBuyDateNow {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #DD0713;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 35px;
  padding: 20px;
  width: 30%;
  border: none;
  font-family: Alice;
  margin-bottom: 20px;
}
.btnBuyDateNow:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: rgb(154, 4, 13);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.btnBuyDateNow:disabled {
  cursor: auto;
  background-color: rgb(138, 73, 81);
  box-shadow: none;
}
.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  flex-direction: column;
}
.spinnerWrapperText {
  font-weight: 800;
  margin-top: 40px;
  font-size: 25px;
  color: #e0566b;
}
.wholeDateInfoWrapperDescription {
  margin-top: 37px;
  padding-bottom: 40px;
}
@media (max-width: 500px) {
  .wholeDate {
    flex-direction: column;
    gap: 20px;
  }
  .wholeDateLeft {
    width: 100%;
  }
  .wholeDateRight {
    width: 100%;
  }
  .wholeDateRightContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wholeDateLocation {
    margin-bottom: 20px;
  }
  .btnBuyDateNow {
    width: 100%;
  }
  .wholeDateInfoWrapper {
    width: 100%;
    text-align: center;
  }
  .wholeDateInfoWrapper div p {
    text-align: center;

  }
  .wholeDateSexWrapper {
    gap: 15px;
    margin-bottom: 15px;
  }
  .wholeDateSexWrapper .sex {
    width: 50%;
  }
  .wholeDateInfoLeft, .wholeDateInfoRight {
    text-align: center;
  }
  .wholeDateInfoWrapperOneRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .wholeDateInfoWrapperDescription {
    margin-top: 17px;
    padding-bottom: 31px;
  }
}