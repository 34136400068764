.adminCards {
  position: relative;
}
.cardEdit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  background-color: lightblue;
  width: 500px;
  padding: 40px 20px 20px 20px;
  box-shadow: 10px 10px 31px 17px rgba(123,200,224,0.8);
  height: 500px;
  overflow-y: scroll;
}
.buttonCloseEdit {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
}
.buttonCloseEdit:hover {
  cursor: pointer;
}
.addDateInputs {
  display: flex;
  width: 800px;
  margin: 0 auto;
  gap: 20px;
  padding: 10px;
}
.addDateInputsLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.addDateInputsRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.adminCards {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 25px auto 0 auto;
}
.adminCardWrapper {
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 0;
}
.adminCard {
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  border: 1px solid black;
  padding: 8px;
}
.adminCardActionButtons {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.adminCardActionButton {
  padding: 5px;
  border: 2px solid red;
  background-color: rgba(154, 4, 13, 0.42);
  font-weight: bold;
}
.adminCardActionButton:hover {
  cursor: pointer;
}
.adminTitle {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.adminLogin {
  border: 2px solid rgba(227, 90, 90, 0.56);
  padding: 15px;
  width: 300px;
  margin: 100px auto 0;
}
.adminLogin input {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
}
.adminLogin h1 {
  text-align: center;
  margin-bottom: 10px;
}
.adminLogin button {
  height: 40px;
  width: 100%;
  background-color: rgba(246, 117, 124, 0.53);
  border: none;
}
.adminLogin button:hover {
  cursor: pointer;
  background-color: rgba(246, 117, 124, 0.8);
}