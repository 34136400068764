.contactsWrapper {
  padding: 20px 24px;
}
.contactsTitle {
  font-weight: 700;
  font-size: 36px;

}
.contactsSubtitle {
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 500;
}
.contactBlock {
  margin-bottom: 30px;
}
.contactBlockTitle {
  font-weight: 700;
  font-size: 20px;
}
.contactBlockValue {
  font-weight: 500;
  font-size: 20px;
}