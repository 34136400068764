.float-label {
  position: relative;
  width: 100%;
  /*margin-bottom: 12px;*/
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
}

.label-float {
  background-color: rgb(246, 246, 246);
  width: auto;
  top: -10px;
  padding: 0 6px;
  font-size: 12px;
}
