/* Force update ant style */
.ant-input {
  padding: 12px 12px 8px 11px;
  background-color: rgb(246, 246, 246);
}
.inputUserInfo {
  padding: 12px 12px 8px 11px;
  background-color: rgb(246, 246, 246);
  border: 1px solid #1f1f1f;
  border-radius: 5px;
}
.twoInputsWrapper .inputUserInfo {
  /*width: 350px;*/
  width: 93%;
}
.twoInputsWrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 15px;
}
.oneInputWrapper .inputUserInfo {
  /*width: 758px;*/
  width: 97%;
}
.floatLabelWrapper {
  width: 93%;
}
.oneInputWrapper {
  margin-bottom: 15px;
}
.errorEmails {
  color: red;
  font-size: 15px;
  margin-top: 0;
  padding-top: 0;
}
.dateInfoName {
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  color: red;

}
.dateInfoPrice {
  font-size: 26px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  text-decoration: underline;
}
.paymentInputsWrapper {
  width: 800px;
  margin: 0 auto;
}

.userDetails {
  border: 1px solid #e8b1c3;
  border-radius: 40px;
  padding: 30px;
  box-sizing: border-box;
  box-shadow: 11px 9px 82px 11px rgba(232,177,195,1);
  margin-bottom: 40px;
}
.userDetailsContent {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}
.cardField {
  padding: 0 5px;
  background-color: rgb(246, 246, 246);
  border: 1px solid #1f1f1f;
  border-radius: 5px;
}
.cardNumber {
  width: 100%;
  margin-bottom: 15px;
}
.cardShortInput {
  width: 60px;
}
.cardYearCvc {
  display: flex;
  gap: 20px;
}
.btnPayWrapper {
  text-align: center;
  margin-top: 20px;
}
.btnPay {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #DD0713;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 35px;
  font-family: Alice;
  padding: 20px 40px;
  border: none;
  text-align: center;
}
.btnPay:hover {
  cursor: pointer;
  background-color: rgb(154, 4, 13);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.agreeBlockText {
  margin-left: 10px;
}
.errorMessageInput {
  color: red;
}
@media (max-width: 500px) {
  .paymentInputsWrapper {
    width: 100%;
  }
  .dateInfoName {
    font-size: 24px;
  }
  .dateInfoPrice {
    font-size: 20px;
    margin-bottom: 20px;

  }
  .userDetails {
    padding: 15px;
  }
  .userDetailsContent {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .twoInputsWrapper {
    flex-direction: column;
    gap: 15px;
  }
  .twoInputsWrapper .inputUserInfo {
    width: 100%;
  }
  .oneInputWrapper .inputUserInfo {
    width: 94%;
  }
  .agreeBlockText {
    font-size: 13px;
  }
  .css-pkm4zg-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0;
  }
  .errorMessageInput {
    font-size: 10px;
  }
}