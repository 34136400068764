.successPurchaseContainer {
  width: 100%;
  height: 100vh;
  background-color: #f16161;
  text-align: center;
}
.successPurchaseContainerText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.successPurchaseContainerTextContent {
  color: white;
  font-size: 50px;
  text-align: center;
  font-weight: 900;
  margin-bottom: 30px;

}
.successPurchaseBtn {
  width: 200px;
  font-size: 20px;
  padding: 20px;
  border: none;
  color: red;
  background-color: white;
  border-radius: 40px;
  font-family: Alice;
  font-weight: 600;
}
.successPurchaseBtn:hover {
  cursor: pointer;
}