.container {
  background-color: rgb(246, 246, 246);

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.payContainer, .cardsWrapper, .contactsWrapper, .wholeDateWrapper, .privacyPolicyContainer, .regulaminContainer {
  flex: 1;
}
