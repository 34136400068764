@import url('https://fonts.googleapis.com/css2?family=Alice&family=Commissioner:wght@100..900&family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alice&family=Commissioner:wght@100..900&family=Inter:wght@100..900&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {

  font-family: "Alice", serif;
}
.FormGroup {
  /*margin: 0 15px 20px;*/
  /*padding: 0;*/
  /*border-style: none;*/
  /*background-color: #7795f8;*/
  /*will-change: opacity, transform;*/
  /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;*/
  /*border-radius: 4px;*/
}

.FormRow {
  /*display: -ms-flexbox;*/
  /*!*display: flex;*!*/
  /*-ms-flex-align: center;*/
  /*align-items: center;*/
  /*margin-left: 15px;*/
  /*border-top: 1px solid #819efc;*/
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

button {
  /*display: block;*/
  /*font-size: 16px;*/
  /*width: calc(100% - 30px);*/
  /*height: 40px;*/
  /*margin: 40px 15px 0;*/
  /*background-color: #f6a4eb;*/
  /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;*/
  /*border-radius: 4px;*/
  /*color: #fff;*/
  /*font-weight: 600;*/
  /*cursor: pointer;*/
  /*transition: all 100ms ease-in-out;*/
  /*will-change: transform, background-color, box-shadow;*/
  /*border: none;*/
}

button:active {
  /*background-color: #d782d9;*/
  /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;*/
  /*transform: scale(0.99);*/
}
