.rulesTitle {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 30px;
}
.privacyPolicyContainer {
  padding: 10px 40px;
}
.rulesItem {
  text-align: justify;
}
.rulesItem {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 500px) {
  .rulesItem {
    text-align: justify;
  }
}