.container {
  background-color: rgb(246, 246, 246);
}
.header {
  padding: 10px 24px;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.logo {
  width: 95px;
  height: 54px;
}
.logoText {
  font-size: 30px;

}
.logoTextImgWrapper {
  max-width: 23%;
  max-height: 100%;
  overflow: hidden; /* This ensures that the image does not overflow its container */
}
.logoTextImgWrapper img {
  width: auto; /* This maintains the aspect ratio */
  height: auto; /* This maintains the aspect ratio */
  max-width: 100%;
  max-height: 100%;
}
.logoImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.navList {
  list-style-type: none;
  gap: 20px;
  display: flex;
  align-items: center;
}
.navItemLink {
  color: rgb(46, 49, 52);
  font-size: 20px;
  text-decoration: none;
}
.navItemLink:hover {
  color: #000;
}
.mainTitle {
  font-weight: bold;
  text-align: center;
  font-size: 32px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.cardsWrapper {
  display: flex;
  justify-content: center;
  padding: 10px 24px;

}
.cards {
  display: flex;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
}
.cardWrapper {

  width: 31%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  flex-basis: 350px;
  flex-grow: 0;
  flex-shrink: 0;
}
.card {
  display: flex;
  flex-direction: column;
  text-decoration: none !important;

}
.cardImgWrapper {
  width: 500px;
  height: 200px;


}
.cardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardImgNameWrapper {
  position: relative;

}
.cardImgName {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(221, 7, 19, 0.8);
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.dateName {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.dateLocation {
  font-size: 18px;
  font-weight: 500;
}
.sexWrapper {
  display: flex;
  margin-top: 15px;
}
.dateInfo {
  padding: 16px;
}
.sex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 50%;
}
.border {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.maleAmount {
  font-size: 20px;
  font-weight: 700;
  color: #34A853;
}
.femaleAmount {
  font-size: 20px;
  font-weight: 700;
  color: #fe01b1;
}
.infoWrapper {
  display: flex;

}
.infoBlock {
  display: flex;
  flex-direction: column;
  width: 33%;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 25px;
}

.infoTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
.infoValue {
  font-size: 16px;
  font-weight: 400;
}
.btnBook {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #DD0713;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 35px;
  margin-bottom: 15px;
  border: none;
  font-family: "Alice", serif;
  font-size: 18px;
  /*width: 100%;*/
  margin-left: 10px;
  margin-right: 10px;

}
.btnBook:hover {
  cursor: pointer;
  background-color: rgb(154, 4, 13);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}
.footer {
  background-color: black;
  color: white;

  padding: 30px 24px 15px;
  margin-top: 50px;

}
.footerRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.footerLogo {
  font-size: 40px;
  font-weight: 900;
}
.footerCardsTitle {
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 10px;

  color: white;
  text-align: center;

}
.footerCards {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.footerList {
  list-style-type: none;
}
.footerListItem {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  transition: 1s;
}
.footerListItemLink {
  color: white;
  text-decoration: none;
}
.footerListItem:hover {
  cursor: pointer;
  text-decoration: underline;
  transition: 1s;

}
.stroked {
  text-decoration: line-through;
}
.footerSocialNetworks {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.socialNetworkImgWrapper {
  width: 25px;
  height: 25px;
}
.socialNetworkImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mainBlock {
  background-image: url("./../../main_bc1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(0.30);
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-end;

}

.mainBlockContent {
  padding-top: 200px;
  padding-bottom: 200px;
  color: white;
  width: 50%;
  font-family: Prata;

}
.mainBlockTitle {
  text-align: left;
  font-size: 70px;
  font-weight: bold;
  color: white;
  margin-bottom: 50px;
}
.mainBlockSubtitle {
  text-align: left;
  font-size: 35px;
  font-weight: 500;
  color: white;
  padding-right: 30px;
}
@media (max-width: 500px) {
  .header {
    padding: 10px 12px;
  }
  .logo {
    width: 50px;
    height: 28px;
  }
  .logoTextImgWrapper {
    max-width: 39%;
    max-height: 100%;
    overflow: hidden;
  }
  .navItemLink {
    font-size: 15px;
  }
  .navItemMobile {
    display: none;
  }
  .cardsWrapper {
    padding: 10px;

  }
  .cards {
    gap: 30px;
    flex-direction: column;
  }
  .cardWrapper {
    width: 100%;
  }
  .cardImgWrapper {
    width: 100%;
    height: 100%;
  }
  .mainTitle {
    font-size: 24px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .cardImgName {
    padding-top: 5px;
    padding-bottom: 5px;
    bottom: 3px;
  }
  .dateName, .dateLocation {
    font-size: 14px;
  }
  .dateInfo {
    padding: 5px;
    margin-bottom: 10px;
  }
  .sex svg {
    width: 20px;
    height: 40px;
  }
  .maleAmount, .femaleAmount {
    font-size: 16px;
  }
  .infoBlock {
    margin-top: 10px;
  }
  .infoTitle {
    font-size: 14px;
  }
  .infoValue {
    font-size: 12px;
  }
  .cardWrapper {
    flex-basis: auto;
  }
  .btnBook {
    font-size: 14px;
  }
  .footerRow {
    flex-direction: column;
    margin-bottom: 15px;
  }
  .footerLogo {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .footerCardsTitle {
    font-size: 20px;
  }
  .footerSocialNetworks {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .mainBlockContent {
    text-align: center;
    padding-top: 150px;
    padding-bottom: 100px;
    color: white;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .mainBlockTitle {
    font-size: 30px;
    text-align: center;

  }
  .mainBlockSubtitle {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
}